@media only screen and (max-width:768px){
    .picture-and-text{
        flex-direction: column;
        margin:0;
        /*align-items: center;*/
    }

    .picture {
        max-width:90vw!important;
        width: 100%;
        margin: 0;
        border-radius: 15px;
        /*box-shadow: 10px 10px 20px lightgrey;*/
    }
}

.picture-and-text{
    display: flex;
    align-items: center;
    margin: 2vh;
    margin-bottom: 15vh;
    position: relative;
    z-index: 1;
    /*background-color: rgba(0,0,0, 0.5);*/
    color: #fff;
}

.main-content-ringing {
    border-radius: 20px;
    display: block;
    margin: 2vh;
    margin-top: 4vh;
    padding: 1vh;
    /*box-shadow: 10px 10px 20px #111;*/
    /*background-color: #000;*/
    background-color: rgba(0,0,0, 0.5);
    position: relative;
    z-index: 1;
}

.main-content-ringing h1{
    text-align: left;
    margin: 2vh;
    color: #cfc288;
    display: block;
    font-size: 2rem;
}

.main-content-ringing p {
    font-size: 1.2rem;
    /*margin: 1vh*/
    color: #fff;
}

.picture{
    max-width:40vw;
    border-radius: 15px;
    /*box-shadow: 10px 10px 20px #111;*/
    /*margin: 1vh;*/
}