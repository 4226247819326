/* webkit Applies to whole project */
::-webkit-scrollbar {
    width: 10px;
    background: #444;
}

::-webkit-scrollbar-track {
    background: #222;
    /* border-radius: 15px; */
    max-height: 50px;
}

::-webkit-scrollbar-thumb {
    background: #333;
    border-radius: 15px;
    height: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: #444;
}

.profile{
    margin-left: 2vw;
    margin-right: 2vw;
    margin-top: 1vh;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1;
}

@media only screen and (max-width:768px){
    .profile {
        flex-direction: column;
    }

    #container{
        max-width: 90vw!important;
        margin: 5vw;
    }
}

#container{
    max-width: 30vw;
    /*z-index: 1;*/
}

#home_photo {
    display: block;
    width: 100%;
    height: auto;
    margin:0;
    clip-path: polygon(10% 0, 100% 0%, 90% 100%, 0% 100%);
    border-radius: 10px;
}

.main-content {
    margin: 1vw;
    padding: 1vw;
    border-radius: 1vw;
    filter: brightness(120%);
    /*box-shadow: 1vw 1vw 1vw #111;*/
    /*background-color: #000;*/
    background-color: rgba(0,0,0, 0.5);
}


.profile h1, h2, h3 {
    margin-top: 1vh;
    margin-bottom: 1vh;
    color: #cfc288;
    font-size: 2rem;
    display: inline-block;
}

.main-content p {
    margin-top:0.1vw;
    line-height: 1.6;
    margin-bottom: 15px;
    font-size: 1.2rem;
    color: #fff;
}