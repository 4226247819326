.dark-mode{
    background-color: #fff;
    border: 1px solid #ffffff;
    border-radius: 15px;
    padding: 2px;
    /* margin: 2px; */
    width: 40px;
    height: 15px;
    cursor: pointer;
    transition: all 1s ease;
    z-index: 1;
    position: relative;
    display: flex;
}

.light-mode{
    background-color: #000;
    border-radius: 15px;
    padding: 2px;
    /* margin: 2px; */
    cursor: pointer;
    width: 40px;
    height: 15px;
    transition: all 1s ease;
    z-index: 1;
    position: relative;
    display: flex;
}


/* Left */
.slider-light{
    position: absolute;
    margin: 0;
    margin-left: 0%;
    left: 3px;
    width: 15px;
    height: 15px;
    border-radius: 15px;
    background-color: #fff;
    transition: all 1s ease;
}

/* Right */
.slider-dark{
    position: absolute;
    margin: 0;
    margin-left: 55%;
    left: 3px;
    right: 3px;
    width: 15px;
    height: 15px;
    border-radius: 15px;
    background-color: #000;
    transition: all 1s ease;
}