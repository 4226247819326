.container{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    z-index: 1;
}

.container-img{
    display: flex;
    align-items: center;
    justify-content: center;
}

.container-img .image-hor{
    display: flex;
    align-items: center;
    justify-content: center;
    transition-duration: 0.75s;
    filter: brightness(0.8);
}

.container-img .image-ver{
    display: flex;
    align-items: center;
    justify-content: center;
    transition-duration: 0.75s;
    filter: brightness(0.8);
}

.container-img:hover .image-ver{
    transition-duration: 0.75s;
    filter: brightness(0.2);
}

.container-img:hover .image-hor{
    transition-duration: 0.75s;
    filter: brightness(0.2);
}

.container-img:hover .text-overlay{
    display: block;
    color:lightgrey;
    animation: fadeIn 0.75s;
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

.image-hor{
    display: flex;
    position: relative;
    margin: 5px;
    max-width:45vw;
}

.image-ver {
    position: relative;
    display: flex;
    margin: 5px;
    max-width: 50vh;
}

.text-overlay{
    position: absolute;
    display: none;
    user-select: none;
}

